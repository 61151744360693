import React, { useCallback } from 'react';

import homePageData from '~/data/home';

import Layout from '~/components/Layout';

import HeroVideo from '~/components/HeroVideo';
import Offices from './components/Offices';
import Testimonials from './components/Testimonials';
import { AnalyticsService } from '~/services/Analytics';
import ManagementTeam from './components/ManagementTeam';
import OurMission from './components/OurMission';
import WhoWeAre from './components/WhoWeAre';

const Home = () => {
  const {
    ourMission,
    whoWeAre,
    entrySection,
    testimonialsSection,
    officesSection,
    managementTeamSection,
  } = homePageData;

  const handleVideoShow = useCallback(() => {
    AnalyticsService.track({
      category: 'Videos',
      action: 'Play',
      label: 'About Us Video',
    });
  }, []);

  return (
    <Layout footerEUSection>
      <HeroVideo onVideoShow={handleVideoShow} data={entrySection} />
      <OurMission data={ourMission} />
      <WhoWeAre data={whoWeAre} />
      <ManagementTeam data={managementTeamSection} />
      <Testimonials data={testimonialsSection} />
      <Offices data={officesSection} />
    </Layout>
  );
};

export default Home;
