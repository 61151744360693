import React from 'react';
import { OurMissionDTO } from '../../types';
import styles from './OurMission.module.scss';

type Props = {
  data: OurMissionDTO;
};

const OurMission: React.FC<Props> = ({ data }) => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.col}>
          <h3 className={styles.title}>{data.title}</h3>
          <p className={styles.content}>{data.content}</p>
        </div>
        <div className={styles.col}>
          <img
            className={styles.photo}
            src={data.photo.src}
            alt={data.photo.alt}
          />
        </div>
      </div>
    </section>
  );
};

export default OurMission;
