import { OfficesSectionDTO } from '~/pages/home/types';

import OfficeWarsaw from './assets/warsaw.svg';
import OfficeTelAviv from './assets/telaviv.svg';
import OfficePaloAlto from './assets/paloalto.svg';
import OfficeDallas from './assets/dallas.svg';
import OfficeAustin from './assets/austin.svg';

const officesSection: OfficesSectionDTO = {
  title: '¡Hola!',
  subtitle: 'Find us all over the world',
  items: [
    {
      city: 'Warsaw',
      image: {
        src: OfficeWarsaw,
        alt: '',
      },
    },
    {
      city: 'Tel Aviv',
      image: {
        src: OfficeTelAviv,
        alt: '',
      },
    },
    {
      city: 'Palo Alto',
      image: {
        src: OfficePaloAlto,
        alt: '',
      },
    },
    {
      city: 'Dallas',
      image: {
        src: OfficeDallas,
        alt: '',
      },
    },
    {
      city: 'Austin',
      image: {
        src: OfficeAustin,
        alt: '',
      },
    },
  ],
};

export default officesSection;
