import { HomePageDTO } from '../../pages/home/types';

import testimonialsSection from './testimonialsSection';
import officesSection from './officesSection';
import entrySection from './entrySection';
import managementTeamSection from './managementTeamSection';
import ourMission from './ourMission';
import whoWeAre from './whoWeAre';

const homePage: HomePageDTO = {
  whoWeAre,
  ourMission,
  entrySection,
  testimonialsSection,
  officesSection,
  managementTeamSection,
};

export default homePage;
