import { OurMissionDTO } from '~/pages/home/types';
import officeInterior from './assets/swing-warsaw-office.jpg';

const ourMission: OurMissionDTO = {
  title: 'Our mission',
  content:
    'We are a team of top-tier developers, designers and business leaders with a non-schematic approach, on a mission to translate innovation into real life. We explore, learn from others and contribute back by modernizing the home insurance industry.',
  photo: {
    alt: 'swingDev warsaw office interior',
    src: officeInterior,
  },
};

export default ourMission;
