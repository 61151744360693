import React from 'react';

import { ManagementTeamMemberDTO } from '../../types';
import styles from './MemberEntry.module.scss';

type Props = {
  data: ManagementTeamMemberDTO;
};

const MemberEntry: React.FC<Props> = ({ data }) => {
  return (
    <article className={styles.root}>
      <div className={styles.photoContainer}>
        <picture>
          <img
            className={styles.photo}
            src={data.photo.src}
            alt={data.photo.alt}
            role='presentation'
          />
        </picture>
      </div>

      <h3 className={styles.name}>{data.name}</h3>
      <p className={styles.position}>{data.position}</p>
      {data.linkedinProfileUrl && (
        <a
          className={styles.link}
          href={data.linkedinProfileUrl}
          rel='noopener noreferrer'
          target='_blank'
          role='button'
        >
          LinkedIn
        </a>
      )}
    </article>
  );
};

export default MemberEntry;
