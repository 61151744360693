import { ManagementTeamSectionDTO } from '~/pages/about-us/types';

import joOverlinePhoto from './assets/jo-overline-photo.jpg';
import marcinMincerPhoto from './assets/marcin-mincer-photo.jpg';
import tomekKopczukPhoto from './assets/tomek-kopczuk-photo.jpg';
const managementTeamSection: ManagementTeamSectionDTO = {
  title: 'Meet our Management Team',
  items: [
    {
      name: 'Jo Overline',
      position: 'Co-Founder & VP at SwingDev, GM at Hippo Poland',
      linkedinProfileUrl: 'https://www.linkedin.com/in/overline/',
      photo: {
        alt: 'Jo Overline Photo',
        src: joOverlinePhoto,
      },
    },
    {
      name: 'Marcin Mincer',
      position: 'Co-Founder at SwingDev & Operations Director at Hippo Poland',
      linkedinProfileUrl: 'https://www.linkedin.com/in/marcinmincer/',
      photo: {
        alt: 'Marcin Mincer Photo',
        src: marcinMincerPhoto,
      },
    },
    {
      name: 'Tomek Kopczuk',
      position:
        'Co-Founder at SwingDev & Director of Security and Tech at Hippo Poland',
      linkedinProfileUrl: 'https://www.linkedin.com/in/tomekkopczuk/',
      photo: {
        alt: 'Tomek Kopczuk Photo',
        src: tomekKopczukPhoto,
      },
    },
  ],
};

export default managementTeamSection;
