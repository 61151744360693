import { EntrySectionDTO } from '~/pages/about-us/types';
import backgroundVideo from './assets/background-video.mp4';
import backgroundVideoPreview from './assets/background-video-preview.png';

const entrySection: EntrySectionDTO = {
  title: 'Swing high. We are here <br/>to give you the push!',
  subtitle:
    'We’re part of the fastest growing InsurTech, where you can be yourself and work with brilliant people.',
  videoUrl: 'https://www.youtube-nocookie.com/embed/MJff60VB91Y',
  backgroundVideo,
  backgroundVideoPreview,
};

export default entrySection;
