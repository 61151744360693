import { TestimonialsSectionDTO } from '~/pages/home/types';

const testimonialsSection: TestimonialsSectionDTO = {
  title: 'References',
  description:
    'Even though we are currently working on one specific product, it wasn`t always like that. We feel proud of the long-lasting relationships we`ve made with our previous clients. Nothing about our work speaks louder than words coming straight from them.',
  items: [
    {
      companyName: 'Samsara',
      companyUrl: 'https://www.samsara.com/',
      content:
        'They really feel like a part of our team. SwingDev has been instrumental in closing multiple deals and has provided continued support. I would recommend them to anyone looking for a rockstar development team or wants to win more deals.',
      refererName: 'Michael R. Nicholson',
      refererPhoto: {
        src: '/avatars/sharespace.jpg',
        alt: 'Samsara',
      },
      refererPosition: 'Strategic Account Executive',
    },
    {
      companyName: 'UpKeep',
      companyUrl: 'https://www.onupkeep.com/',
      content:
        'Working with the SwingDev has been a dream! They are very knowledgeable and extremely responsive. They are adaptable when we need a proposal by a certain deadline and are accommodating when needed.',
      refererName: 'Rachel Wittlin',
      refererPhoto: {
        src: '/avatars/upkeep.jpg',
        alt: '',
      },
      refererPosition: 'Senior Implementation Manager',
    },
    {
      companyName: 'ZigZag',
      companyUrl: 'https://www.zigzagtheapp.com/',
      content:
        'Our cooperation with SwingDev developed into a great long-term working relationship. I love how professional everything is. They guarantee me high quality - with Swing on our side, we were able to elevate our app. To me, they are rockstars!',
      refererName: 'Ben Jaimen',
      refererPhoto: {
        src: '/avatars/zigzag.jpg',
        alt: '',
      },
      refererPosition: 'CEO',
      videoUrl: 'https://www.youtube-nocookie.com/embed/FKLZRnWpJ5o',
    },
    {
      companyName: 'Sharespace',
      companyUrl: '',
      content:
        'They’re personable and proactively engage challenges to accomplish development goals. Their organization and communication skills yield excellent code.',
      refererName: 'Daniel Kluesing',
      refererPhoto: {
        src: '/avatars/sharespace2.jpg',
        alt: '',
      },
      refererPosition: 'CEO',
    },
    {
      companyName: 'Advanced-HR',
      companyUrl: 'https://www.advanced-hr.com/',
      content:
        'Our Experience with SwingDev has been wonderful. They are a part of our staff and we would not enjoy our success without them.',
      refererName: 'Dee DiPietro',
      refererPhoto: {
        src: '/avatars/advanced.jpg',
        alt: '',
      },
      refererPosition: 'Founder & CEO',
    },
  ],
};

export default testimonialsSection;
