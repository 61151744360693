import React, { RefObject, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { TestimonialsSectionDTO, TestimonialDTO } from '../../types';
import styles from './Testimonials.module.scss';
import { Breakpoint } from '~/constants';
import Carousel, { SlickSlider, CarouselSettings } from '~/components/Carousel';
import { VideoPreview } from '~/components/VideoPreview';
import Image from '~/components/Image';
import { AnalyticsService } from '~/services/Analytics';

const carouselSettings: CarouselSettings = {
  infinite: true,
  arrows: false,
  autoplay: false,
  autoplaySpeed: 5000,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  responsive: [
    {
      breakpoint: Breakpoint.MEDIUM,
      settings: {
        infinite: true,
        arrows: false,
        autoplay: false,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        dotsClass: styles.dots,
      },
    },
  ],
};

type Props = {
  data: TestimonialsSectionDTO;
};

const Video = ({ title, video }: { title: string; video: string }) => {
  const [showVideo, setShowVideo] = useState(false);
  const handleVideoClose = () => setShowVideo(false);
  const handleVideoOpen = () => setShowVideo(true);

  return (
    <>
      <button onClick={handleVideoOpen} className={styles.videoBtn}>
        Watch video
      </button>
      <VideoPreview
        videoUrl={video}
        title={title}
        show={showVideo}
        onClose={handleVideoClose}
      />
    </>
  );
};

const renderTestimonial = (testimonial: TestimonialDTO, index?: number) => {
  return (
    <article className={styles.testimonial} key={index || uniqueId()}>
      <div className={styles.testimonialContent}>
        <div className={styles.contentContainer}>
          <p className={styles.content}>{testimonial.content}</p>
          <div className={styles.referer}>
            {testimonial.videoUrl && (
              <Video
                title={testimonial.companyName}
                video={testimonial.videoUrl}
              />
            )}
            <div className={styles.person}>
              <div className={styles.photoContainer}>
                <Image
                  className={styles.avatar}
                  alt={testimonial.refererPhoto.alt}
                  img={testimonial.refererPhoto.src}
                />
              </div>
              <div>
                <span className={styles.name}>{testimonial.refererName}</span>
                {testimonial.refererPosition},{' '}
                {testimonial.companyUrl ? (
                  <a
                    href={testimonial.companyUrl}
                    className={styles.companyName}
                  >
                    {testimonial.companyName}
                  </a>
                ) : (
                  <span className={styles.companyName}>
                    {testimonial.companyName}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

const Testimonials: React.FC<Props> = ({
  data: { items, title, description },
}) => {
  const carousel: RefObject<SlickSlider> = React.createRef();

  const changeSlide = (direction: 'left' | 'right') => {
    if (carousel && carousel.current) {
      if (direction === 'left') {
        carousel.current.slickNext();

        AnalyticsService.track({
          category: 'Homepage',
          action: 'Testimonials Arrow Click',
          label: 'Right Arrow',
        });
      }

      if (direction === 'right') {
        carousel.current.slickPrev();

        AnalyticsService.track({
          category: 'Homepage',
          action: 'Testimonials Arrow Click',
          label: 'Left Arrow',
        });
      }
    }
  };

  return (
    <section className={styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <div className={styles.arrowContainer}>
        <button
          onClick={() => changeSlide('left')}
          className={styles.arrowLeft}
        />
        <div className={styles.container}>
          <Carousel {...carouselSettings} forwardRef={carousel}>
            {items.map(renderTestimonial)}
          </Carousel>
        </div>
        <button
          onClick={() => changeSlide('right')}
          className={styles.arrowRight}
        />
      </div>
    </section>
  );
};

export default Testimonials;
