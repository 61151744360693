import { WhoWeAreDTO } from '~/pages/home/types';

const whoWeAre: WhoWeAreDTO = {
  title: 'Who we are',
  subtitle: 'We’re part of a fast-growing insurtech company — Hippo Insurance.',
  content:
    'For years, we were a software development and design consultancy, working with top companies from Silicon Valley. We have successfully built long-term relationships with our clients based on transparency and trust, and as a result, we merged with Hippo last year. Hippo’s technology-first approach is modernizing the otherwise stale home insurance industry, from IoT monitoring devices to our industry-leading software. <br/>Even though we’ve become a part of something bigger, by joining SwingDev you’ll feel the startup vibe created by people who simply like each other and have each other’s back. We believe the culture of trust and empathy keeps us together, and it led us to where we are today - feeling happy with our work and interacting with people we call friends.<br/><br/>',
};

export default whoWeAre;
