import React from 'react';

import { OfficeDTO, OfficesSectionDTO } from '../../types';

import styles from './Offices.module.scss';

export interface Props {
  data: OfficesSectionDTO;
}

const renderOfficeElement = (office: OfficeDTO, index: number) => {
  return (
    <li key={index} className={styles.item}>
      <div className={styles.landmark}>
        <img src={office.image.src} alt={office.image.alt} />
      </div>
      <p className={styles.city}>{office.city}</p>
    </li>
  );
};

const Offices: React.FC<Props> = ({ data }) => (
  <section className={styles.root}>
    <div className={styles.container}>
      <h2 className={styles.title}>{data.title}</h2>
      <h3 className={styles.subtitle}>{data.subtitle}</h3>

      <ul className={styles.list}>{data.items.map(renderOfficeElement)}</ul>
    </div>
  </section>
);

export default Offices;
