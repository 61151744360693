import React from 'react';
import { WhoWeAreDTO } from '../../types';
import styles from './WhoWeAre.module.scss';

type Props = {
  data: WhoWeAreDTO;
};

const WhoWeAre: React.FC<Props> = ({ data }) => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <h3 className={styles.title}>{data.title}</h3>
        <h4 className={styles.subtitle}>{data.subtitle}</h4>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
    </section>
  );
};

export default WhoWeAre;
